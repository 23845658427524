import React from 'react';
import { Avatar } from '@material-ui/core';
import logo from './smallthings.drawio.svg';
export default (function () {
  return React.createElement("img", {
    src: logo,
    style: {
      height: '40px',
      width: '50px'
    }
  });
});