import React from 'react';
import { Grid, Typography } from '@material-ui/core';
import { LinkedinBox, TwitterBox as Twitter } from 'mdi-material-ui';
import { useTheme } from '@material-ui/styles';

var Footer = function Footer() {
  var theme = useTheme();
  return React.createElement("footer", {
    style: {
      marginLeft: 'auto',
      marginRight: 'auto',
      width: '80%',
      marginTop: theme.spacing(12),
      marginBottom: theme.spacing(2)
    }
  }, React.createElement(Grid, {
    container: true,
    direction: "row"
  }, React.createElement(Grid, {
    item: true,
    style: {
      flexGrow: 1
    }
  }, React.createElement(Typography, {
    color: "textSecondary"
  }, "\xA9 ", new Date().getFullYear(), " small|things")), false && React.createElement(Grid, {
    item: true
  }, React.createElement("a", {
    href: "https://twitter.com/CompliancePal",
    style: {
      textDecoration: 'none'
    },
    target: "twitter"
  }, React.createElement(Typography, {
    color: "textSecondary"
  }, React.createElement(Twitter, {
    style: {
      paddingLeft: theme.spacing(0.5),
      marginRight: theme.spacing(0.5)
    }
  })))), false && React.createElement(Grid, {
    item: true
  }, React.createElement("a", {
    href: "https://www.linkedin.com/company/compliancepal",
    style: {
      textDecoration: 'none'
    },
    target: "linkedin"
  }, React.createElement(Typography, {
    color: "textSecondary"
  }, React.createElement(LinkedinBox, {
    style: {
      borderLeft: '1px solid gray',
      paddingLeft: theme.spacing(0.5),
      marginRight: theme.spacing(0.5)
    }
  })))), React.createElement(Grid, {
    item: true
  }, React.createElement("a", {
    href: "mailto:hello@smallthin.gs",
    style: {
      textDecoration: 'none'
    }
  }, React.createElement(Typography, {
    color: "textSecondary"
  }, "hello@smallthin.gs")))));
};

export default Footer;