import React from 'react';
import Layout from '../components/layout';
import { Container, Typography } from '@material-ui/core';
import remark from 'remark';
import remark2react from 'remark-react';
import { withStyles } from '@material-ui/styles';

var styles = function styles(theme) {
  return {
    title: {
      marginBottom: theme.spacing(5),
      marginTop: theme.spacing(3)
    }
  };
};

var DevopsFeature = function DevopsFeature(_ref) {
  var classes = _ref.classes,
      data = _ref.data,
      location = _ref.location;
  return React.createElement(Layout, {
    location: location
  }, React.createElement(Container, {
    maxWidth: "md"
  }, React.createElement(Typography, {
    variant: "h4",
    className: classes.title
  }, data.markdownRemark.frontmatter.title), remark().use(remark2react, {
    remarkReactComponents: {
      p: function p(_ref2) {
        var children = _ref2.children;
        return React.createElement(Typography, null, children);
      },
      li: function li(_ref3) {
        var children = _ref3.children;
        return React.createElement("li", null, React.createElement(Typography, null, children));
      }
    }
  }).processSync(data.markdownRemark.rawMarkdownBody).contents));
};

export default withStyles(styles)(DevopsFeature);
export var devopsFeatureQuery = "2289641444";