import "core-js/modules/es6.function.name";
import "core-js/modules/es6.string.starts-with";
import staticQueryData from "../../public/static/d/1649957398.json";
import React from 'react';
import { Typography, AppBar, Toolbar, Button } from '@material-ui/core';
import CompliancePalAvatar from './CompliancePalAvatar';
import { useTheme, makeStyles } from '@material-ui/styles';
import { Link, StaticQuery } from 'gatsby';
var useStyles = makeStyles(function (theme) {
  return {
    section: {
      textDecoration: 'none',
      marginRight: theme.spacing(2),
      '&:hover': {
        color: theme.palette.text.primary
      }
    }
  };
});

var Header = function Header(_ref) {
  var location = _ref.location;
  var theme = useTheme();
  var classes = useStyles(theme);
  return React.createElement(React.Fragment, null, React.createElement(AppBar, {
    color: "default",
    position: "static"
  }, React.createElement(Toolbar, {
    variant: "dense",
    style: {
      margin: 'auto',
      width: '90%'
    }
  }, React.createElement(CompliancePalAvatar, null), React.createElement(Link, {
    to: "/",
    style: {
      textDecoration: 'none'
    }
  }, React.createElement(Typography, {
    variant: "h6",
    style: {
      marginLeft: theme.spacing(1),
      color: 'gray',
      fontWeight: 'bolder',
      fontSize: '1.3125rem',
      cursor: 'pointer'
    }
  }, "small|things")), React.createElement(Typography, {
    style: {
      flexGrow: 1
    }
  }), React.createElement(StaticQuery, {
    query: sectionsQuery,
    render: function render(data) {
      return data.site.siteMetadata.sections.map(function (section) {
        return React.createElement(Typography, {
          key: section.to,
          to: section.to,
          variant: "h6",
          className: classes.section,
          color: location.pathname.startsWith(section.to) ? 'textPrimary' : 'textSecondary',
          component: Link
        }, section.name);
      });
    },
    data: staticQueryData
  }))));
};

export default Header;
export var sectionsQuery = "1649957398";